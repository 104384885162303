import React from "react";
import submarino from "../images/projects/submarino.png";
import rent_friend from "../images/projects/rent_a_local_friend.png";
import fgv from "../images/projects/fgv.png";
import jump_park from "../images/projects/jump_park.png";
import wrs from "../images/projects/wrs.png";
import synas from "../images/projects/synas.png";
import { useTranslation } from "react-i18next";

const Companies = () => {
  const { t } = useTranslation();

  return (
    <div id="clients" className="skills">
      <h1 className="py-5"> {t("companies-title")}</h1>
      <div className="container">
        <div className="row">
          {/* {-----} */}
          <div className="col">
            <div className="box">
              <div className="square">
                <div className="photo-wrap mb-5">
                  <img className="icon-img" src={submarino} alt="Icon" />
                </div>
              </div>
            </div>
          </div>
          {/* {-----} */}
          <div className="col">
            <div className="box">
              <div className="square">
                <div className="photo-wrap mb-5">
                  <img className="icon-img" src={rent_friend} alt="Icon" />
                </div>
              </div>
            </div>
          </div>
          {/* {-----} */}
          <div className="col">
            <div className="box">
              <div className="square">
                <div className="photo-wrap mb-5">
                  <img className="icon-img-fgv" src={fgv} alt="Icon" />
                </div>
              </div>
            </div>
          </div>
          {/* {-----} */}
          <div className="col">
            <div className="box">
              <div className="square">
                <div className="photo-wrap mb-5">
                  <img
                    className="icon-img-jump-park"
                    src={jump_park}
                    alt="Icon"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* {-----} */}
          <div className="col">
            <div className="box">
              <div className="square">
                <div className="photo-wrap mb-5">
                  <img className="icon-img-wrs" src={wrs} alt="Icon" />
                </div>
              </div>
            </div>
          </div>
          {/* {-----} */}
          <div className="col">
            <div className="box">
              <div className="square">
                <div className="photo-wrap mb-5">
                  <img className="icon-img-synas" src={synas} alt="Icon" />
                </div>
              </div>
            </div>
          </div>
          {/* {-----} */}
        </div>
      </div>
    </div>
  );
};

export default Companies;
