import React from "react";

import githubIcon from "../icons/github.svg";
import linkedinIcon from "../icons/linkedin.svg";
import marcio from "../images/team/marcio.jpg";
import lipert from "../images/team/lipert.jpg";
import nicolas from "../images/team/nicolas.jpeg";

import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();

  const profiles = [
    {
      name: "Nicolas de Barros",
      role: t("dev-full"),
      socialMedia: ["github", "linkedin"],
      githubProfile: "https://github.com/Nicolas123-coder",
      linkedinProfile: "https://www.linkedin.com/in/nicolasdebarros/",
      profileImage: nicolas,
    },
    {
      name: "João Lipert",
      role: t("dev-back"),
      socialMedia: ["github", "linkedin"],
      githubProfile: "https://github.com/Lipert1",
      linkedinProfile: "https://www.linkedin.com/in/jo%C3%A3ovitorlimalipert/",
      profileImage: lipert,
    },
    {
      name: "Marcio Alves",
      role: "CEO",
      socialMedia: ["github", "linkedin"],
      githubProfile: "https://github.com/MarcioAmadeus",
      linkedinProfile:
        "https://www.linkedin.com/in/m%C3%A1rcio-alves-37b52529/",
      profileImage: marcio,
    },
  ];

  const icons = {
    github: githubIcon,
    linkedin: linkedinIcon,
  };

  return (
    <div id="team" className="team">
      <h1 className="py-5">{t("team")}</h1>
      <div className="team-container">
        <div className="profile-cards-container">
          {profiles.map((profile, index) => (
            <div key={index} className="profile-card">
              <div className="profile-image">
                <img src={profile.profileImage} alt="Profile" />
              </div>
              <div className="profile-info">
                <h2>{profile.name}</h2>
                <p>{profile.role}</p>
                <div className="social-media-icons">
                  {profile.socialMedia.map((media, index) => {
                    const IconComponent = icons[media];
                    const profileLink =
                      media === "linkedin"
                        ? profile.linkedinProfile
                        : profile.githubProfile;

                    return (
                      <a
                        key={index}
                        href={profileLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={IconComponent}
                          alt={`${media} icon`}
                          className={`icon-team icon-${media}`}
                        />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
