import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCode } from "@fortawesome/free-regular-svg-icons";
import { faUserShield, faRocket } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import {
  faLaptopCode,
  faCloudUploadAlt,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div id="services" className="services">
      <h1 className="py-5">{t("services")}</h1>
      <div className="container">
        <div className="row">
          {/* - */}
          <div className="col-md-4 col-sm-6">
            <div className="box">
              <div className="circle">
                <FontAwesomeIcon
                  className="icon"
                  icon={faLaptopCode}
                  size="2x"
                />
              </div>
              <h4>{t("web-dev-title")}</h4>
              <p>{t("web-dev")}</p>
            </div>
          </div>
          {/* - */}
          <div className="col-md-4 col-sm-6">
            <div className="box">
              <div className="circle">
                <FontAwesomeIcon className="icon" icon={faFileCode} size="2x" />
              </div>
              <h4>{t("api-dev-title")}</h4>
              <p>{t("api-dev")}</p>
            </div>
          </div>
          {/* - */}
          <div className="col-md-4 col-sm-6">
            <div className="box">
              <div className="circle">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCloudUploadAlt}
                  size="2x"
                />
              </div>
              <h4>{t("infra-dev-title")}</h4>
              <p>{t("infra-dev")}</p>
            </div>
          </div>
          {/* - */}
          <div className="col-md-4 col-sm-6">
            <div className="box">
              <div className="circle">
                <FontAwesomeIcon className="icon" icon={faDatabase} size="2x" />
              </div>
              <h4>{t("db-dev-title")}</h4>
              <p>{t("db-dev")}</p>
            </div>
          </div>
          {/* - */}
          <div className="col-md-4 col-sm-6">
            <div className="box">
              <div className="circle">
                <FontAwesomeIcon
                  className="icon"
                  icon={faUserShield}
                  size="2x"
                />
              </div>
              <h4>{t("sec-dev-title")}</h4>
              <p>{t("sec-dev")}</p>
            </div>
          </div>
          {/* - */}
          <div className="col-md-4 col-sm-6">
            <div className="box">
              <div className="circle">
                <FontAwesomeIcon className="icon" icon={faRocket} size="2x" />
              </div>
              <h4>{t("growth-title")}</h4>
              <p>{t("growth")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
