import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/Navbar";
import Header from "./components/Header";
import Companies from "./components/Companies";
import Services from "./components/Services";
import Team from "./components/Team";
import Footer from "./components/Footer";
import Contacts from "./components/Contacts";
import Projects from "./components/Projects";

function App() {
  return (
    <>
      <NavBar />
      <Header />
      <Services />
      <Companies />
      <Team />
      <Projects />
      <Contacts />
      <Footer />
    </>
  );
}

export default App;
