import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import project1 from "../images/projects/1.png";
import project2 from "../images/projects/2.png";
import project3 from "../images/projects/3.png";
import project4 from "../images/projects/4.png";
import { useTranslation } from "react-i18next";

const Projects = () => {
  const { t } = useTranslation();

  return (
    <div id="portfolio" className="portfolio-wrapper">
      <div className="container">
        <h1 className="text-uppercase text-center py-5">{t("projects")}</h1>
        <div className="image-box-wrapper row justify-content-center">
          {/* - */}
          <div className="portfolio-image-box">
            <img
              className="portfolio-image"
              src={project1}
              alt="Netflix Clone Project..."
            />
          </div>
          {/* - */}
          <div className="portfolio-image-box">
            <img
              className="portfolio-image"
              src={project2}
              alt="City Guide Project..."
            />
          </div>
          {/* - */}
          <div className="portfolio-image-box">
            <img
              className="portfolio-image"
              src={project3}
              alt="Portfolio React and Material UI Project..."
            />
          </div>
          {/* - */}
          <div className="portfolio-image-box">
            <img
              className="portfolio-image"
              src={project4}
              alt="Task Manager React and Redux Project..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
