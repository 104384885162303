import React from "react";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-2 col-sm-6">
            <div className="row">
              <div className="col">
                <Link
                  smooth={true}
                  to="home"
                  offset={-110}
                  className="footer-nav"
                >
                  Home
                </Link>
                <br />
                <Link
                  smooth={true}
                  to="services"
                  offset={-110}
                  className="footer-nav"
                >
                  {t("services")}
                </Link>
                <br />
                <Link
                  smooth={true}
                  to="clients"
                  offset={-110}
                  className="footer-nav"
                >
                  {t("clients")}
                </Link>
              </div>
              <div className="col">
                <Link
                  smooth={true}
                  to="team"
                  offset={-110}
                  className="footer-nav"
                >
                  {t("team")}
                </Link>
                <br />
                <Link
                  smooth={true}
                  to="portfolio"
                  offset={-110}
                  className="footer-nav"
                >
                  {t("projects")}
                </Link>
              </div>
            </div>
          </div>
          {/* Alteração aqui: Utilizei flexbox para alinhar o texto à direita */}
          <div className="col-lg-9 col-md-10 col-sm-6 d-flex justify-content-end align-items-center">
            <p className="pt-3 text-right">
              Copyright&copy;{new Date().getFullYear()}&nbsp;Lambda Bytes | All
              Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
