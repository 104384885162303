import React from "react";
import Typed from "react-typed";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div id="home" className="header-wraper">
      <div className="main-info">
        <h1>{t("header-title-1")}</h1>
        <h1>{t("header-title-2")}</h1>

        <Typed
          className="typed-text"
          strings={[
            "Front-end",
            "Back-End",
            "JavaScript",
            "Python",
            "Node js",
            "React js",
          ]}
          typeSpeed={40}
          backSpeed={60}
          loop
        />
        <Link
          href="#"
          smooth={true}
          to="contacts"
          offset={-110}
          className="btn-main-offer"
        >
          {t("contact-us")}
        </Link>
      </div>
    </div>
  );
};

export default Header;
