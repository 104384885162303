import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "../locales/en/translation.json";
import translationPT from "../locales/pt/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  pt: {
    translation: translationPT,
  },
};

i18n
  .use(initReactI18next) // passa o i18n para o react-i18next
  .use(LanguageDetector) // passa o detector de idiomas
  .init({
    resources,
    // lng: "en", // remova ou comente esta linha
    fallbackLng: "en", // idioma de reserva
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["cookie"], // onde armazenar a detecção do idioma
    },
    interpolation: {
      escapeValue: false, // não é necessário para react pois ele escapa por padrão
    },
  });

export default i18n;
